import React, { useEffect } from "react"
import styles from "./load-balancer-anim.module.scss"
import anime from "animejs"

const LoadBalancerGraphAnim = () => {
  const totalDuration = 2000
  const inboundDuration = 1000
  const inboundAnimDuration = 1000
  const innerDuration = 500
  const outboundDuration = 1100
  const outboundAnimDuration = 1000
  const inoutAltDuration = 1000
  const fadeinOutDuration = 1000
  const dashFadeIn = 0.7
  const lineOpacity = 0.8
  const lineFadeOut = 0.4

  let maintl
  let inboundDash
  let outboundDash

  let maintlMob
  let inboundDashMob
  let outboundDashMob

  const startDashes = () => {
    inboundDash.play()
    outboundDash.play()
  }

  const startDashesMob = () => {
    inboundDashMob.play()
    outboundDashMob.play()
  }

  useEffect(() => {
    maintl = anime.timeline({
      easing: "easeInOutQuad",
      duration: totalDuration,
      autoplay: true,
    })

    inboundDash = anime.timeline({
      easing: "linear",
      duration: inboundAnimDuration,
      loop: true,
      autoplay: false,
    })

    outboundDash = anime.timeline({
      easing: "linear",
      duration: outboundAnimDuration,
      loop: true,
      autoplay: false,
    })

    maintlMob = anime.timeline({
      easing: "easeInOutQuad",
      duration: totalDuration,
      autoplay: true,
    })

    inboundDashMob = anime.timeline({
      easing: "linear",
      duration: inboundAnimDuration,
      loop: true,
      autoplay: false,
    })

    outboundDashMob = anime.timeline({
      easing: "linear",
      duration: outboundAnimDuration,
      loop: true,
      autoplay: false,
    })

    inboundDashMob.add({
      targets: [
        "#minbound1-dash",
        "#minbound2-dash",
        "#minner1-dash",
        "#minner2-dash",
      ],
      duration: inboundAnimDuration,
      strokeDashoffset: [10, 0],
    })

    outboundDashMob.add({
      targets: [
        "#moutbound1-dash",
        "#moutbound2-dash",
        "#minbound-alt1-dash",
        "#moutbound-alt1-dash",
      ],
      duration: outboundAnimDuration,
      strokeDashoffset: [12, 0],
    })

    inboundDash.add({
      targets: [
        "#inbound1-dash",
        "#inbound2-dash",
        "#inner1-dash",
        "#inner2-dash",
      ],
      duration: inboundAnimDuration,
      strokeDashoffset: [10, 0],
    })

    outboundDash.add({
      targets: [
        "#outbound1-dash",
        "#outbound2-dash",
        "#inbound-alt1-dash",
        "#outbound-alt1-dash",
      ],
      duration: outboundAnimDuration,
      strokeDashoffset: [12, 0],
    })

    maintl
      .add({
        targets: "#img",
        duration: 900,
        opacity: [0, 1],
        translateY: [-8, 1],
      })
      .add(
        {
          targets: "#inbound1",
          duration: inboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [65, 0],
        },
        "-=400"
      )
      .add(
        {
          targets: "#inbound2",
          duration: inboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [65, 0],
        },
        "-=500"
      )
      .add({
        targets: "#inner1",
        duration: innerDuration,
        opacity: [0, lineOpacity],
        strokeDashoffset: [30, 0],
      })
      .add(
        {
          targets: "#inner2",
          duration: innerDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [30, 0],
        },
        "-=200"
      )
      .add(
        {
          targets: "#outbound1",
          duration: outboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [100, 0],
        },
        "-=200"
      )
      .add(
        {
          targets: "#outbound2",
          duration: outboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [100, 0],
        },
        "-=1100"
      )
      .add(
        {
          targets: "#inbound-alt1",
          duration: inoutAltDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [100, 0],
        },
        "-=200"
      )
      .add(
        {
          targets: "#outbound-alt1",
          duration: inoutAltDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [100, 0],
        },
        "-=300"
      )
      .add({
        targets: [
          "#inbound1",
          "#inbound2",
          "#inner1",
          "#inner2",
          "#outbound1",
          "#outbound2",
          "#inbound-alt1",
          "#outbound-alt1",
        ],
        duration: fadeinOutDuration,
        opacity: lineFadeOut,
        complete: startDashes,
      })
      .add(
        {
          targets: [
            "#inbound1-dash",
            "#inbound2-dash",
            "#inner1-dash",
            "#inner2-dash",
            "#outbound1-dash",
            "#outbound2-dash",
            "#inbound-alt1-dash",
            "#outbound-alt1-dash",
          ],
          duration: fadeinOutDuration,
          opacity: [0, dashFadeIn],
        },
        "-=500"
      )

    maintlMob
      .add({
        targets: "#img",
        duration: 900,
        opacity: [0, 1],
        translateY: [-8, 1],
      })
      .add(
        {
          targets: "#minbound1",
          duration: inboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [20, 0],
        },
        "-=400"
      )
      .add(
        {
          targets: "#minbound2",
          duration: inboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [20, 0],
        },
        "-=500"
      )
      .add({
        targets: "#minner1",
        duration: innerDuration,
        opacity: [0, lineOpacity],
        strokeDashoffset: [22, 0],
      })
      .add(
        {
          targets: "#minner2",
          duration: innerDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [22, 0],
        },
        "-=200"
      )
      .add(
        {
          targets: "#moutbound1",
          duration: outboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [100, 0],
        },
        "-=200"
      )
      .add(
        {
          targets: "#moutbound2",
          duration: outboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [100, 0],
        },
        "-=1100"
      )
      .add(
        {
          targets: "#minbound-alt1",
          duration: inoutAltDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [100, 0],
        },
        "-=200"
      )
      .add(
        {
          targets: "#moutbound-alt1",
          duration: inoutAltDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [100, 0],
        },
        "-=300"
      )
      .add({
        targets: [
          "#minbound1",
          "#minbound2",
          "#minner1",
          "#minner2",
          "#moutbound1",
          "#moutbound2",
          "#minbound-alt1",
          "#moutbound-alt1",
        ],
        duration: fadeinOutDuration,
        opacity: lineFadeOut,
        complete: startDashesMob,
      })
      .add(
        {
          targets: [
            "#minbound1-dash",
            "#minbound2-dash",
            "#minner1-dash",
            "#minner2-dash",
            "#moutbound1-dash",
            "#moutbound2-dash",
            "#minbound-alt1-dash",
            "#moutbound-alt1-dash",
          ],
          duration: fadeinOutDuration,
          opacity: [0, dashFadeIn],
        },
        "-=500"
      )
  }, [])

  return (
    <div className={styles.anim}>
      <div className={styles.anim__mobile}>
        <img
          width="384"
          height="542"
          id="img"
          className={styles.anim__img}
          src="/img/products/voltmesh-mclb-graph-global-network-mobile.png"
          alt="Distributed Apps automation graph"
        />
        <svg
          className={styles.anim__svg}
          width="384px"
          height="542px"
          viewBox="0 0 384 542"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g id="Artboard" transform="translate(-710.000000, -907.000000)">
              <g id="Group-3" transform="translate(710.000000, 907.000000)">
                <rect id="canvas" x="0" y="0" width="384" height="542"></rect>
                <path
                  d="M214,364 L241.038549,364 C243.247688,364 245.038549,362.209139 245.038549,360 L245.038549,327 C245.038549,321.477153 249.515701,317 255.038549,317 L273,317 L273,317"
                  id="moutbound1"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="100"
                  transform="translate(243.500000, 340.500000) rotate(-270.000000) translate(-243.500000, -340.500000) "></path>
                <path
                  d="M144,364 L171.038549,364 C173.247688,364 175.038549,362.209139 175.038549,360 L175.038549,327 C175.038549,321.477153 179.515701,317 185.038549,317 L203,317 L203,317"
                  id="moutbound2"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="100"
                  transform="translate(173.500000, 340.500000) scale(1, -1) rotate(-90.000000) translate(-173.500000, -340.500000) "></path>
                <path
                  d="M152,364 L181.038549,364 C186.009112,364 190.038549,359.970563 190.038549,355 L190.038549,321 C190.038549,318.790861 191.82941,317 194.038549,317 L211,317 L211,317"
                  id="moutbound-alt1"
                  stroke="#A611A5"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="100"
                  transform="translate(181.500000, 340.500000) scale(1, -1) rotate(-90.000000) translate(-181.500000, -340.500000) "></path>
                <line
                  x1="212.5"
                  y1="76"
                  x2="212.5"
                  y2="95"
                  id="minbound1"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="66"></line>
                <line
                  x1="206.5"
                  y1="76"
                  x2="206.5"
                  y2="95"
                  id="minbound2"
                  stroke="#F94627"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="66"></line>
                <line
                  x1="209.040476"
                  y1="154.780316"
                  x2="209.040476"
                  y2="172.780316"
                  id="minner1"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="28"></line>
                <line
                  x1="209"
                  y1="230.5"
                  x2="209"
                  y2="248.780316"
                  id="minner2"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="28"></line>
                <line
                  x1="212.5"
                  y1="76"
                  x2="212.5"
                  y2="95"
                  id="minbound1-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,5"></line>
                <line
                  x1="206.5"
                  y1="76"
                  x2="206.5"
                  y2="95"
                  id="minbound2-dash"
                  stroke="#F94627"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,5"></line>
                <line
                  x1="209.040476"
                  y1="154.780316"
                  x2="209.040476"
                  y2="172.780316"
                  id="minner1-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,5"></line>
                <line
                  x1="209.040476"
                  y1="230.780316"
                  x2="209.040476"
                  y2="248.780316"
                  id="minner2-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,5"></line>
                <path
                  d="M266,317 L250.038549,317 C247.277125,317 245.038549,319.238576 245.038549,322 L245.038549,355 C245.038549,359.970563 241.009112,364 236.038549,364 L207,364 L207,364"
                  id="minbound-alt1"
                  stroke="#A611A5"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="100"
                  transform="translate(236.500000, 340.500000) rotate(-270.000000) translate(-236.500000, -340.500000) "></path>
                <path
                  d="M214,364 L241.038549,364 C243.247688,364 245.038549,362.209139 245.038549,360 L245.038549,327 C245.038549,321.477153 249.515701,317 255.038549,317 L273,317 L273,317"
                  id="moutbound1-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,6"
                  transform="translate(243.500000, 340.500000) rotate(-270.000000) translate(-243.500000, -340.500000) "></path>
                <path
                  d="M144,364 L171.038549,364 C173.247688,364 175.038549,362.209139 175.038549,360 L175.038549,327 C175.038549,321.477153 179.515701,317 185.038549,317 L203,317 L203,317"
                  id="moutbound2-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,6"
                  transform="translate(173.500000, 340.500000) scale(1, -1) rotate(-90.000000) translate(-173.500000, -340.500000) "></path>
                <path
                  d="M152,364 L181.038549,364 C186.009112,364 190.038549,359.970563 190.038549,355 L190.038549,321 C190.038549,318.790861 191.82941,317 194.038549,317 L211,317 L211,317"
                  id="moutbound-alt1-dash"
                  stroke="#A611A5"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,6"
                  transform="translate(181.500000, 340.500000) scale(1, -1) rotate(-90.000000) translate(-181.500000, -340.500000) "></path>
                <path
                  d="M266,317 L250.038549,317 C247.277125,317 245.038549,319.238576 245.038549,322 L245.038549,355 C245.038549,359.970563 241.009112,364 236.038549,364 L207,364 L207,364"
                  id="minbound-alt1-dash"
                  stroke="#A611A5"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,6"
                  transform="translate(236.500000, 340.500000) rotate(-270.000000) translate(-236.500000, -340.500000) "></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className={styles.anim__desktop}>
        <img
          id="img"
          className={styles.anim__img}
          src="/img/products/voltmesh-mclb-graph-global-network.png"
          alt="Distributed Apps automation graph"
        />
        <svg
          className={styles.anim__svg}
          width="541px"
          height="314px"
          viewBox="0 0 541 314"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g id="Artboard" transform="translate(-68.000000, -73.000000)">
              <g id="Group-19-Copy" transform="translate(68.000000, 73.000000)">
                <rect
                  id="Rectangle"
                  x="0"
                  y="0"
                  width="540"
                  height="333"></rect>
                <path
                  d="M353,187 L380.038549,187 C382.247688,187 384.038549,185.209139 384.038549,183 L384.038549,150 C384.038549,144.477153 388.515701,140 394.038549,140 L412,140 L412,140"
                  id="outbound1"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="100"></path>
                <path
                  d="M353,257 L380.038549,257 C382.247688,257 384.038549,255.209139 384.038549,253 L384.038549,220 C384.038549,214.477153 388.515701,210 394.038549,210 L412,210 L412,210"
                  id="outbound2"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="100"
                  transform="translate(382.500000, 233.500000) scale(1, -1) translate(-382.500000, -233.500000) "></path>
                <path
                  d="M353,249 L382.038549,249 C387.009112,249 391.038549,244.970563 391.038549,240 L391.038549,206 C391.038549,203.790861 392.82941,202 395.038549,202 L412,202 L412,202"
                  id="outbound-alt1"
                  stroke="#A611A5"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="100"
                  transform="translate(382.500000, 225.500000) scale(1, -1) translate(-382.500000, -225.500000) "></path>
                <line
                  x1="49.2398401"
                  y1="195.5"
                  x2="115"
                  y2="195.5"
                  id="inbound1"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="66"></line>
                <line
                  x1="49.2398401"
                  y1="201.5"
                  x2="115"
                  y2="201.5"
                  id="inbound2"
                  stroke="#F94627"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="66"></line>
                <line
                  x1="177"
                  y1="199.5"
                  x2="204.560633"
                  y2="199.5"
                  id="inner1"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="28"></line>
                <line
                  x1="264"
                  y1="199.5"
                  x2="291.560633"
                  y2="199.5"
                  id="inner2"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="28"></line>
                <line
                  x1="49.2398401"
                  y1="195.5"
                  x2="116.23984"
                  y2="195.5"
                  id="inbound1-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,5"></line>
                <line
                  x1="49.2398401"
                  y1="201.5"
                  x2="116.23984"
                  y2="201.5"
                  id="inbound2-dash"
                  stroke="#F94627"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,5"></line>
                <line
                  x1="177"
                  y1="199.5"
                  x2="204.560633"
                  y2="199.5"
                  id="inner1-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,5"></line>
                <line
                  x1="264"
                  y1="199.5"
                  x2="291.560633"
                  y2="199.5"
                  id="inner2-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,5"></line>
                <path
                  d="M412,147 L396.038549,147 C393.277125,147 391.038549,149.238576 391.038549,152 L391.038549,185 C391.038549,189.970563 387.009112,194 382.038549,194 L353,194 L353,194"
                  id="inbound-alt1"
                  stroke="#A611A5"
                  strokeWidth="3.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="100"></path>
                <path
                  d="M353,187 L380.038549,187 C382.247688,187 384.038549,185.209139 384.038549,183 L384.038549,150 C384.038549,144.477153 388.515701,140 394.038549,140 L412,140 L412,140"
                  id="outbound1-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,6"></path>
                <path
                  d="M353,257 L380.038549,257 C382.247688,257 384.038549,255.209139 384.038549,253 L384.038549,220 C384.038549,214.477153 388.515701,210 394.038549,210 L412,210 L412,210"
                  id="outbound2-dash"
                  stroke="#6783F1"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,6"
                  transform="translate(382.500000, 233.500000) scale(1, -1) translate(-382.500000, -233.500000) "></path>
                <path
                  d="M353,249 L382.038549,249 C387.009112,249 391.038549,244.970563 391.038549,240 L391.038549,206 C391.038549,203.790861 392.82941,202 395.038549,202 L412,202 L412,202"
                  id="outbound-alt1-dash"
                  stroke="#A611A5"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,6"
                  transform="translate(382.500000, 225.500000) scale(1, -1) translate(-382.500000, -225.500000) "></path>
                <path
                  d="M412,147 L396.038549,147 C393.277125,147 391.038549,149.238576 391.038549,152 L391.038549,185 C391.038549,189.970563 387.009112,194 382.038549,194 L353,194 L353,194"
                  id="inbound-alt1-dash"
                  stroke="#A611A5"
                  strokeWidth="3.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,6"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default LoadBalancerGraphAnim

import styles from "../../styles/voltmesh-features.module.scss"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import checksStyles from "../../styles/commons/circlechecks.module.scss"
import React, { useState } from "react"
import Layout from "../../components/layout/layout"
import StartDemo from "../../components/start-demo/start-demo"
import LoadBalancerGraphAnim from "../../components/animated-graphs/load-balancer/load-balancer-anim"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"
import withAppContext from "../../contexts/app.context"
import { ENV_CONSOLE_BASE_URL } from "../../utils/secrets"
import { ProductsUseCases } from "../../components/products-use-cases/products-use-cases"
import UseCaseBenefits from "../../components/use-case-benefit/use-case-benefits"
import ComparisonTable from "../../components/comparison-table/comparison-table"
import CascadeVideo from "../../components/cascade-video/cascade-video"
import MclbHeroAnim from "../../components/animated-graphs/voltmesh-mclb-hero/voltmesh-mclb-hero"
import ArrowSvg from "../../components/svg/arrow"

const VoltMeshLbPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "products__voltmesh-mclb" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={
          "/products/voltmesh-multi-cluster-load-balancer-and-api-gateway"
        }
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container volterra-container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>
                  Multi-Cluster Load Balancer & API Gateway
                </h1>
                <a
                  aria-label="Start for Free"
                  href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                  className={"btn btn-primary " + styles.header__btn}>
                  Start for Free
                </a>
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}>
            <MclbHeroAnim />
          </div>
        </div>
      </section>

      {/* Intro */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Built for Modern & Distributed Apps that Require Automation,
              Performance & Visibility
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.textblock}>
                <p className={cblockStyles.cblock__intro}>
                  VoltMesh is a SaaS-based service and automatically deploys in
                  your cloud VPCs/VNETs. VoltMesh instances across multiple app
                  clusters seamlessly connect into our global network and use
                  our control plane to provide a globally distributed load
                  balancer, API gateway and app security.
                </p>
                <p className={cblockStyles.cblock__intro}>
                  VoltConsole provides centralized policy, observability,
                  lifecycle management and automation across all VoltMesh
                  instances.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-n3">
              <LoadBalancerGraphAnim />
            </div>
          </div>
        </div>
      </section>

      {/* Main Features */}
      <UseCaseBenefits
        props={{
          title:
            "Improve Reliability, Performance & Security of Your Apps across Clusters or Clouds",
          imgUrl: "/img/products/",
          content: {
            benefits: [
              {
                iconName: "shield-alt-icon.svg",
                title: "Reliability",
                description: `Reduce downtime by relying on our advanced traffic management and global control plane that distributes endpoint health across all clusters and cloud locations`,
              },
              {
                iconName: "gauge-icon.svg",
                title: "Performance",
                description: `Provide consistent performance by connecting clusters in multiple clouds using our global network with the option to terminate end user TLS connections`,
              },
              {
                iconName: "shield-alt2-icon.svg",
                title: "Security",
                description: `Reduce risks with multi-layer security including ML-powered API discovery, DDoS mitigation, automated policy, and end-to-end encryption for compliance`,
              },
              {
                iconName: "automation-icon.svg",
                title: "Automation",
                description: `Simplify automation teams with Volterra public APIs, Terraform provider, and vesctl. In addition, IAM and multi-tenancy provide app teams with a self-service capability`,
              },
            ],
          },
        }}
      />

      {/* Key Capabilities */}
      <div
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__mr}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Key Capabilities</h2>
          </div>
          <ProductsUseCases inlineIcons={true}>
            <div
              title="Load Balancer"
              link={"/docs/services/mesh/load-balancing"}
              imgurl="/img/products/load-balancer-icon.svg"
              imgalt="Load Balancer icon">
              <ul className={"list-unstyled mt-4 " + checksStyles.circlechecks}>
                <li className={checksStyles.circlechecks__item}>
                  DNS service and Anycast IP or GLB
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Service discovery (K8s, Consul, DNS)
                </li>
                <li className={checksStyles.circlechecks__item}>
                  HTTP, TCP, UDP load balancing
                </li>
                <li className={checksStyles.circlechecks__item}>
                  HTTP connect and forward proxy
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Traffic management and session persistence
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Service mesh and tracing integration
                </li>
              </ul>
            </div>
            <div
              title="API Gateway"
              link={"/docs/services/mesh/api-gateway"}
              imgurl="/img/products/api-gateway-icon.svg"
              imgalt="API Gateway icon">
              <ul className={"list-unstyled mt-4 " + checksStyles.circlechecks}>
                <li className={checksStyles.circlechecks__item}>
                  Authentication and authorization
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Role-based access control
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Rate limiting
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Advanced policy
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Metering and accounting
                </li>
                <li className={checksStyles.circlechecks__item}>
                  API and developer portal
                </li>
              </ul>
            </div>
            <div
              title="App Security"
              link={"/docs/services/mesh/application-security"}
              imgurl="/img/products/app-security-icon.svg"
              imgalt="App Security icon">
              <ul className={"list-unstyled mt-4 " + checksStyles.circlechecks}>
                <li className={checksStyles.circlechecks__item}>
                  L3-L7+ DDoS protection
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Web app firewall (WAF)
                </li>
                <li className={checksStyles.circlechecks__item}>
                  API discovery and control
                </li>
                <li className={checksStyles.circlechecks__item}>
                  User-behavior detection
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Anomaly detection
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Network and app policy
                </li>
              </ul>
            </div>
            <div
              title="Operations"
              link={"/docs/services/app-stack/observability"}
              imgurl="/img/products/operations-alt-icon.svg"
              imgalt="Operations icon">
              <ul className={"list-unstyled mt-4 " + checksStyles.circlechecks}>
                <li className={checksStyles.circlechecks__item}>
                  Automated deployment and upgrades
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Multi-tenancy for collaboration
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Log and metrics collection + retention
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Analytics and visualization
                </li>
                <li className={checksStyles.circlechecks__item}>
                  Alerting with email, SMS, Slack
                </li>
                <li className={checksStyles.circlechecks__item}>
                  3rd party integrations - OpsGenie, Datadog...
                </li>
              </ul>
            </div>
          </ProductsUseCases>
        </div>
      </div>

      {/* Comparison Table */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              VoltMesh vs. Traditional Gateways
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ComparisonTable
              props={{
                cols: ["Features", "Traditional", "VoltMesh"],
                rows: [
                  {
                    name: "API Gateway",
                    cells: ["Included", "Included"],
                  },
                  {
                    name: "Distributed traffic management",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Distributed control plane",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Multi-layer security (AI powered)",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Multi-tenant platform",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Logging & metrics",
                    cells: ["External", "Included"],
                  },
                  {
                    name: "Lifecycle management",
                    cells: ["Manual", "SaaS"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Deploy VoltMesh within Minutes */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__vmtlmr} ${cblockStyles.cblock__grey}`}>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Deploy VoltMesh within Minutes
            </h2>
          </div>

          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item 1 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>1</span>
                      <h5>Deploy VoltMesh</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Fully automated provisioning of VoltMesh in cloud using
                        the UI or our vesctl CLI - as simple as picking the
                        cloud provider, region, and AZ
                      </p>
                      <a
                        href="/docs/how-to/site-management/create-aws-site"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 1 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896970.hd.mp4?s=279c3e302cad9c62029d3c03fb720fa944c6cc7a&profile_id=174",
                                  videoLength: "10000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/453328124.sd.mp4?s=dd6ffa8eeddf160e9426084acd93bfce68cb6058&profile_id=165",
                                  videoLength: "21000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 2 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>2</span>
                      <h5>Delegate Domain</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Delegate a domain or subdomain to manage creation of
                        public DNS entries and automated provisioning of TLS
                        certificates
                      </p>
                      <a
                        href="/docs/how-to/app-networking/domain-delegation"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 2 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449897014.hd.mp4?s=76e90950aa985a200c07ef4c08acfcbccc0cbd34&profile_id=174",
                                  videoLength: "15000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896996.hd.mp4?s=44b599da00c483767a8698572090f761cf2a645f&profile_id=174",
                                  videoLength: "27000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 3 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>3</span>
                      <h5>
                        Discover & Route Services
                        <img
                          className={`ml-2 ${styles.topfeatsslider__caret}`}
                          src="/img/products/caret-icon.svg"
                          alt=">"
                        />
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Configure service discovery of K8s, Consul, or DNS
                        services and implement routing + policy rules to direct
                        incoming requests to discovered services
                      </p>
                      <a
                        href="/docs/how-to/app-networking/http-load-balancer"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 3 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449897025.hd.mp4?s=0a8d0bc08b30a86fffde12101ce314f1cc1ff2c1&profile_id=174",
                                  videoLength: "37000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449897023.hd.mp4?s=e2318e82ea02d32c83d67fe3c71c84e93845072e&profile_id=174",
                                  videoLength: "33000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 4 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>4</span>
                      <h5>Send Requests</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Send requests to the specified service or APIs and let
                        VoltMesh handle routing of the requests to the right
                        cluster and endpoint
                      </p>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 4 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/450101701.hd.mp4?s=edf00623a68c168c262fc0a1879f268fe31772e9&profile_id=174",
                                  videoLength: "33000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449897061.hd.mp4?s=b2c2ac330ea4543b26020f5f5e726691c4c36afd&profile_id=174",
                                  videoLength: "14000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896970.hd.mp4?s=279c3e302cad9c62029d3c03fb720fa944c6cc7a&profile_id=174",
                              videoLength: "10000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/453328124.sd.mp4?s=dd6ffa8eeddf160e9426084acd93bfce68cb6058&profile_id=165",
                              videoLength: "21000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/449897014.hd.mp4?s=76e90950aa985a200c07ef4c08acfcbccc0cbd34&profile_id=174",
                              videoLength: "15000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896996.hd.mp4?s=44b599da00c483767a8698572090f761cf2a645f&profile_id=174",
                              videoLength: "27000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/449897025.hd.mp4?s=0a8d0bc08b30a86fffde12101ce314f1cc1ff2c1&profile_id=174",
                              videoLength: "37000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/449897023.hd.mp4?s=e2318e82ea02d32c83d67fe3c71c84e93845072e&profile_id=174",
                              videoLength: "33000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/450101701.hd.mp4?s=edf00623a68c168c262fc0a1879f268fe31772e9&profile_id=174",
                              videoLength: "33000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/449897061.hd.mp4?s=b2c2ac330ea4543b26020f5f5e726691c4c36afd&profile_id=174",
                              videoLength: "14000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* <FeaturesSlider withNumbers="true" useGif="true">
              <FeaturesSliderContentPane
                title="Deploy VoltMesh"
                desc="Fully automated provisioning of VoltMesh in cloud using the UI or our vesctl CLI - as simple as picking the cloud provider, region, and AZ"
                link="/solutions/multi-cloud/multi-cloud-networking">
              </FeaturesSliderContentPane>
              <FeaturesSliderContentPane
                title="Delegate Domain"
                desc="Delegate a domain or subdomain to manage creation of public DNS entries and automated provisioning of TLS certificates"
                link="/solutions/multi-cloud/multi-cloud-networking">
                <p>This is Delegate Domain content</p>
              </FeaturesSliderContentPane>
              <FeaturesSliderContentPane
                title="Discover & Route Services"
                desc="Configure service discovery of K8s, Consul, or DNS services and implement routing + policy rules to direct incoming requests to discovered services"
                link="/solutions/multi-cloud/multi-cloud-networking">
                <p>This is Discover & Route Services content</p>
              </FeaturesSliderContentPane>
              <FeaturesSliderContentPane
                title="Send Requests"
                desc="Send requests to the specified service or APIs and let VoltMesh handle routing of the requests to the right cluster and endpoint"
                link="/solutions/multi-cloud/multi-cloud-networking">
                <p>This is Send Requests content</p>
              </FeaturesSliderContentPane>
            </FeaturesSlider> */}
          </div>
        </div>
      </section>

      <StartDemo
        props={{
          benefits: [
            "Secure K8s gateway",
            "Multi-cluster load balancer",
            "Web app security",
            "Multi-cloud networking",
          ],
        }}
      />
    </Layout>
  )
}

export default withAppContext(VoltMeshLbPage)
